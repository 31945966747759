<template>
    <v-dialog v-model="dialog" max-width="85vh" width="85%" :disabled="!src" content-class="black">
        <template v-slot:activator="{ attrs, on }">
            <v-avatar v-bind="{ ...attrs, ...avatarAttrs }" v-on="on" :style="!src ? 'cursor:initial' : ''">
                <v-img :src="src" alt="" v-bind="$attrs">
                    <template v-slot:placeholder>
                        <v-icon dark>mdi-image</v-icon>
                    </template>
                </v-img>
            </v-avatar>
        </template>

        <v-img v-if="src" :src="src" max-height="85vh" contain>
            <v-btn absolute right top icon tile dark x-large @click="dialog = false" style="background-color: rgba(0, 0, 0, 0.3)">
                <v-icon size="4rem">mdi-close</v-icon>
            </v-btn>
        </v-img>
    </v-dialog>
</template>

<script>
export default {
    props: {
        size: { type: String, default: null },
        src: { type: String, default: undefined },
        width: { type: String, default: null },
        height: { type: String, default: null },
        rounded: { type: Boolean, default: false },
    },
    computed: {
        avatarAttrs() {
            const { size, width, height, rounded } = this;
            return {
                size,
                width,
                height,
                tile: !rounded,
                rounded,
                color: "grey lighten-1",
            };
        },
    },
    data() {
        return { dialog: false };
    },
};
</script>

<style></style>
