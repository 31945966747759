var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("제품 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("제품 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "name",
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "item-text": "name",
      "item-value": "name",
      "label": "게시판 노출순서",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.priority,
      callback: function ($$v) {
        _vm.$set(_vm.board, "priority", $$v);
      },
      expression: "board.priority"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "한줄설명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.summary,
      callback: function ($$v) {
        _vm.$set(_vm.board, "summary", $$v);
      },
      expression: "board.summary"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.board.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "썸네일 이미지(280*280)",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-card-subtitle', [_vm._v("이미지(793*793)")]), _c('v-divider'), _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-card-text', [_vm.imgSrcs.length ? _c('v-tabs-items', {
    attrs: {
      "value": _vm.image_selected
    }
  }, _vm._l(_vm.imgSrcs, function (src) {
    return _c('v-tab-item', {
      key: src
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('work-imgs-overlay', {
      attrs: {
        "files": _vm.imgSrcs.map(function (url) {
          return {
            url
          };
        }),
        "disableLink": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on,
            setIndex = _ref.setIndex;
          return [_c('v-img', _vm._g(_vm._b({
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "src": src,
              "width": "100%",
              "aspect-ratio": 1 / 1
            },
            on: {
              "click": function ($event) {
                return setIndex({
                  url: src
                });
              }
            }
          }, 'v-img', attrs, false), on))];
        }
      }], null, true)
    })], 1)], 1);
  }), 1) : _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "0",
      "color": "grey darken-1"
    }
  }, [_vm._v("이미지가 없습니다")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('images-input', {
    on: {
      "mouseover": _vm.setImage_selected
    },
    model: {
      value: _vm.board.files,
      callback: function ($$v) {
        _vm.$set(_vm.board, "files", $$v);
      },
      expression: "board.files"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7"
    }
  }, [_c('v-card-subtitle', [_vm._v("5줄 설명")]), _c('v-divider'), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "a",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.description[0],
      callback: function ($$v) {
        _vm.$set(_vm.board.description, 0, $$v);
      },
      expression: "board.description[0]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "b",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.description[1],
      callback: function ($$v) {
        _vm.$set(_vm.board.description, 1, $$v);
      },
      expression: "board.description[1]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "c",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.description[2],
      callback: function ($$v) {
        _vm.$set(_vm.board.description, 2, $$v);
      },
      expression: "board.description[2]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "d",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.description[3],
      callback: function ($$v) {
        _vm.$set(_vm.board.description, 3, $$v);
      },
      expression: "board.description[3]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "e",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.description[4],
      callback: function ($$v) {
        _vm.$set(_vm.board.description, 4, $$v);
      },
      expression: "board.description[4]"
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v(" 제품 사이즈 & 설명 "), _c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "tile": "",
      "fab": "",
      "color": "grey lighten-2",
      "width": "20px",
      "height": "20px"
    },
    on: {
      "click": function ($event) {
        return _vm.onClick(_vm.board.size);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('draggable', {
    model: {
      value: _vm.board.size,
      callback: function ($$v) {
        _vm.$set(_vm.board, "size", $$v);
      },
      expression: "board.size"
    }
  }, [_c('transition-group', _vm._l(_vm.board.size, function (size, index) {
    return _c('v-list-item', {
      key: `${index}`
    }, [_c('v-col', {
      attrs: {
        "sm": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "제품사이즈",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: size.name,
        callback: function ($$v) {
          _vm.$set(size, "name", $$v);
        },
        expression: "size.name"
      }
    })], 1), _c('v-col', {
      attrs: {
        "sm": "12"
      }
    }, [_c('v-textarea', {
      attrs: {
        "label": "제품사이즈 설명",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: size.content,
        callback: function ($$v) {
          _vm.$set(size, "content", $$v);
        },
        expression: "size.content"
      }
    })], 1), _c('v-list-item-action', {
      attrs: {
        "sm": "2"
      }
    }, [_c('v-icon', {
      on: {
        "click": function ($event) {
          return _vm.board.size.splice(index, 1);
        }
      }
    }, [_vm._v("mdi-delete")])], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save(_vm.board.size);
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }