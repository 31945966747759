var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm.isCreate ? [_vm._v("생성")] : [_vm._v("상세")], _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "5"
    }
  }, [_c('v-card-subtitle', [_vm._v("썸네일(382*382)")]), _c('v-divider'), _c('v-card-text', [_c('v-preview-input', {
    attrs: {
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.inputs.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "thumb", $$v);
      },
      expression: "inputs.thumb"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMobile,
      expression: "!isMobile"
    }],
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMobile,
      expression: "isMobile"
    }]
  })], 1), _c('v-col', [_c('v-card-subtitle', [_vm._v("기본 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-text-field', {
    staticClass: "my-3",
    attrs: {
      "label": "광고솔루션 시리즈"
    },
    model: {
      value: _vm.inputs.subject,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "subject", $$v);
      },
      expression: "inputs.subject"
    }
  }), _c('v-text-field', {
    staticClass: "my-3",
    attrs: {
      "label": "광고솔루션 서브 제목"
    },
    model: {
      value: _vm.inputs.subTitle,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "subTitle", $$v);
      },
      expression: "inputs.subTitle"
    }
  }), _c('v-text-field', {
    staticClass: "my-3",
    attrs: {
      "label": "광고솔루션 설명"
    },
    model: {
      value: _vm.inputs.content,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "content", $$v);
      },
      expression: "inputs.content"
    }
  }), _c('v-select', {
    staticClass: "my-3",
    attrs: {
      "label": "헤더 노출여부",
      "items": ['true', 'false']
    },
    model: {
      value: _vm.inputs.show,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "show", $$v);
      },
      expression: "inputs.show"
    }
  }), _c('v-text-field', {
    staticClass: "my-3",
    attrs: {
      "label": "헤더 노출순서"
    },
    model: {
      value: _vm.inputs.priority,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "priority", $$v);
      },
      expression: "inputs.priority"
    }
  }), _c('v-text-field', {
    staticClass: "my-3",
    attrs: {
      "label": "게시판 노출순서"
    },
    model: {
      value: _vm.inputs.boardNum,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "boardNum", $$v);
      },
      expression: "inputs.boardNum"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 저장하기 ")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }