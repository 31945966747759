var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1280",
      "width": "85%",
      "dark": "",
      "overlay-color": "black",
      "overlay-opacity": "0.65",
      "content-class": "elevation-0",
      "no-click-animation": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          setIndex: _vm.setIndex
        })];
      }
    }], null, true),
    model: {
      value: _vm.showsDialog,
      callback: function ($$v) {
        _vm.showsDialog = $$v;
      },
      expression: "showsDialog"
    }
  }, [_c('v-sheet', {
    staticClass: "px-3",
    attrs: {
      "max-height": "90vh",
      "color": "transparent"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "fab": "",
      "text": "",
      "disabled": _vm.disable_prev
    },
    on: {
      "click": _vm.goPrev
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-chevron-left")])], 1)], 1), _c('v-col', [_c('v-tabs-items', {
    staticStyle: {
      "background": "transparent"
    },
    attrs: {
      "value": _vm.selectedIndex
    }
  }, _vm._l(_vm.imgs, function (img, index) {
    return _c('v-tab-item', {
      key: img._id,
      staticStyle: {
        "background": "transparent"
      },
      attrs: {
        "value": index
      }
    }, [_c('a', {
      attrs: {
        "href": img.url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_c('v-img', {
      staticStyle: {
        "background": "transparent"
      },
      attrs: {
        "src": img.url,
        "aspect-ratio": 1 / 1,
        "width": "100%",
        "height": "70vh",
        "contain": ""
      }
    })], 1)]);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "fab": "",
      "text": "",
      "disabled": _vm.disable_next
    },
    on: {
      "click": _vm.goNext
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', _vm._l(_vm.imgs, function (img, index) {
    return _c('v-col', {
      key: img._id,
      staticClass: "pa-1",
      staticStyle: {
        "position": "relative"
      },
      attrs: {
        "cols": "2"
      },
      on: {
        "mouseenter": function ($event) {
          return _vm.setIndex(img);
        }
      }
    }, [_c('v-fade-transition', [_c('v-overlay', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.selectedIndex == index,
        expression: "selectedIndex == index"
      }],
      attrs: {
        "absolute": "",
        "z-index": "0",
        "color": "white",
        "opacity": "1"
      }
    })], 1), _c('v-img', {
      attrs: {
        "src": img.url,
        "aspect-ratio": 1 / 1,
        "width": "100%"
      }
    })], 1);
  }), 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsCloseBtn,
      expression: "showsCloseBtn"
    }],
    staticClass: "mr-n4 mt-9",
    attrs: {
      "absolute": "",
      "top": "",
      "right": "",
      "fab": "",
      "x-large": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.showsDialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }