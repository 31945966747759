<template>
    <v-row justify="center" align="center">
        <v-col cols="auto" class="headline text-start">{{ title }}</v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
            <slot name="buttons" />
            <v-btn v-if="showsFilterButton" color="secondary" rounded class="ml-3" @click="$emit('input', !value)"><v-icon small>mdi-filter-variant</v-icon>검색필터</v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },

        title: { type: String, default: "목록" },
        showsFilterButton: { type: Boolean, default: false },
    },
};
</script>

<style></style>
