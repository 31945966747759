<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">제품 등록</div>
            <div v-else class="headline text-start mt-8">제품 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-select v-model="board.category" :items="categories" item-text="name" item-value="name" label="카테고리" persistent-placeholder hide-details></v-select>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.priority" item-text="name" item-value="name" label="게시판 노출순서" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="board.summary" label="한줄설명" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="board.thumb" max-width="96" max-height="96" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="board.thumb" label="썸네일 이미지(280*280)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-card-subtitle>이미지(793*793)</v-card-subtitle>
                        <v-divider />
                        <v-responsive :aspect-ratio="1 / 1">
                            <v-card-text>
                                <v-tabs-items v-if="imgSrcs.length" :value="image_selected">
                                    <v-tab-item v-for="src in imgSrcs" :key="src">
                                        <v-responsive :aspect-ratio="1 / 1">
                                            <work-imgs-overlay :files="imgSrcs.map((url) => ({ url }))" disableLink>
                                                <template #activator="{ attrs, on, setIndex }">
                                                    <v-img :src="src" width="100%" :aspect-ratio="1 / 1" style="cursor: pointer" v-bind="attrs" v-on="on" @click="setIndex({ url: src })" />
                                                </template>
                                            </work-imgs-overlay>
                                        </v-responsive>
                                    </v-tab-item>
                                </v-tabs-items>
                                <v-img v-else :aspect-ratio="1 / 1">
                                    <v-overlay absolute z-index="0" color="grey darken-1">이미지가 없습니다</v-overlay>
                                </v-img>
                            </v-card-text>
                        </v-responsive>
                        <v-divider />
                        <v-card-actions>
                            <images-input v-model="board.files" @mouseover="setImage_selected" />
                        </v-card-actions>
                    </v-col>
                    <v-col cols="12" sm="7">
                        <v-card-subtitle>5줄 설명</v-card-subtitle>
                        <v-divider />
                        <v-text-field v-model="board.description[0]" label="a" persistent-placeholder hide-details class="mt-5"></v-text-field>
                        <v-text-field v-model="board.description[1]" label="b" persistent-placeholder hide-details class="mt-5"></v-text-field>
                        <v-text-field v-model="board.description[2]" label="c" persistent-placeholder hide-details class="mt-5"></v-text-field>
                        <v-text-field v-model="board.description[3]" label="d" persistent-placeholder hide-details class="mt-5"></v-text-field>
                        <v-text-field v-model="board.description[4]" label="e" persistent-placeholder hide-details class="mt-5"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">
                    제품 사이즈 & 설명
                    <v-btn tile fab color="grey lighten-2" class="mx-4" width="20px" height="20px" @click="onClick(board.size)">
                        <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                </v-card-title>
                <v-row class="mt-4">
                    <draggable v-model="board.size">
                        <transition-group>
                            <v-list-item v-for="size, index in board.size" :key="`${index}`">
                                <v-col sm="4">
                                    <v-text-field v-model="size.name" label="제품사이즈" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col sm="12">
                                    <v-textarea v-model="size.content" label="제품사이즈 설명" persistent-placeholder hide-details></v-textarea>
                                </v-col>
                                <v-list-item-action sm="2"> 
                                    <v-icon @click="board.size.splice(index, 1)">mdi-delete</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </transition-group>
                    </draggable>
                </v-row>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save(board.size)">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import WorkImgsOverlay from "@/components/console/auction/work-imgs-overlay.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";

import draggable from "vuedraggable";


export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
        WorkImgsOverlay,
        ImagesInput,
        draggable
    },
    data() {
        return {
            ready: false,

            categories: [],

            show: false,

            code: null,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {   
                language: "ko",     
                code: null,
                category: undefined,

                priority: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,

                description: [],

                thumb: undefined,
                files: [],
                
                image_selected: null,
                size: []

            },

        };
    },
    computed: {
        imgSrcs() {
            if(this.board.files == undefined) this.board.files = []
            return this.board.files.map((file) => URL.createObjectURL(file));
        },
        showsAddButton() {
            let { maxLength } = this;
            maxLength = +maxLength;
            if (maxLength == 0) return true;
            if (maxLength < 0) return false;
            return (this.files || [])?.length < maxLength;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {

                this.board.code = this.$route.query.code;

                if (this.$route.params._board) {
                    var { board } = await api.console.boards.get({ _id: this.$route.params._board });
                    board.files = await Promise.all((board.files || []).map(async ({ url }) => await api.getResource(url, true)));
                    if (board.thumb) board.thumb = await api.getResource(board.thumb, true);
                    this.board = board;
                }
                
                //광고목록 불러오기
                let { programs } = await api.console.auction.programs.gets({ params: { code: this.board.code, language: "ko" } });
                this.categories = programs.map(program => program.subject)

                if (!this.board.size.length) {
                this.board.size = [...this.board.size, { name: null, content: null }];
                }

                
                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save(size) {
            try {
                this.board.size = [ ...size.map((el) => ({ name: el.name, content: el.content }))];
                let { _files = [], files = [], thumb, ...board } = this.board;
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 삭제
                ////////////////////////////////////////////////////////////////
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.console.boards.files.delete({ _board: board?._id, _id })));

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ board }] = [board._id ? await api.console.boards.put(board) : await api.console.boards.post(board)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.boards.postThumb(board, thumb);
                if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.console.boards.files.post({ _board: board?._id, index }, file)));

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        setImage_selected(file) {
            const index = this.board.files.findIndex((img) => img == file);
            this.image_selected = index;
        },
        onClick(size) {
            this.board.size = [ ...size.map((el) => ({ name: el.name, content: el.content })), this.board.size ]
        }
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        "board.files.length"() {
            this.image_selected = this.board?.files?.length - 1;
        },
    },
};
</script>
