<template>
    <v-container fluid fill-height class="console-terms">
        <v-layout v-if="setting" justify-center>
            <v-responsive max-width="720px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">카탈로그관리</v-col>
                    <v-spacer></v-spacer>
                    <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <v-row align="center">
                            <v-col cols="auto pt-0">일반 카탈로그 첨부파일</v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-file-input v-model="setting.enSurveyURL" :label="`${setting?.enSurveyURL?.originalname}`" persistent-placeholder dense hide-details></v-file-input>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <v-row align="center">
                            <v-col cols="auto pt-0">아트배너 스탠드 카탈로그 첨부파일</v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-file-input v-model="setting.enSurveyURL2" :label="`${setting?.enSurveyURL2?.originalname}`" persistent-placeholder dense hide-details></v-file-input>
                    </v-card-text>
                </v-card>

                <v-btn fixed bottom right fab x-large color="primary" @click="save">
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import vPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import FooterInfo from "@/components/console/setting/footer-info.vue";
import FooterSns from "@/components/console/setting/footer-sns.vue";

export default {
    components: {
        vPasswordField,
        FooterInfo,
        FooterSns,
    },
    created() {
        this.init();
    },
    data() {
        return {
            setting: {
                enSurveyURL: null,
            },
            file: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.setting.get();
            this.setting = setting;
        },

        async save() {
            await api.console.setting.putEnSurveyURL(this.setting);
            await api.console.setting.putEnSurveyURL2(this.setting);
            alert("저장되었습니다.");
        },
    },
};
</script>
