var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "시리즈 목록"
    },
    model: {
      value: _vm.showSearch,
      callback: function ($$v) {
        _vm.showSearch = $$v;
      },
      expression: "showSearch"
    }
  }), _c('v-data-table', {
    staticClass: "my-4",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers,
      "hide-default-footer": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: "item.thumb",
      fn: function (_ref2) {
        var value = _ref2.value;
        return _c('div', {
          staticClass: "py-4"
        }, [_c('image-popup', {
          attrs: {
            "src": value === null || value === void 0 ? void 0 : value.url,
            "size": "48"
          }
        })], 1);
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('program-view', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var attrs = _ref4.attrs,
                on = _ref4.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "color": "primary",
                  "large": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }), _c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "my-4",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('program-view', {
    attrs: {
      "value": _vm.filter
    },
    on: {
      "input": _vm.update
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var attrs = _ref5.attrs,
          on = _ref5.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "fab": "",
            "fixed": "",
            "right": "",
            "bottom": "",
            "color": "primary",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }