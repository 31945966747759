<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3">
            <div class="headline text-start mt-8">{{ headline }}</div>
            <v-card class="mt-6">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row v-if="$route.params._form">
                        <v-col cols="6" lg="3">
                            <v-text-field :value="(form.writer || {}).companyName" label="회사명" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="(form.writer || {}).name" label="작성자" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly disabled />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="(form.writer || {}).phone" label="연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="(form.writer || {}).email" label="이메일" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="(form.writer || {}).type" label="문의유형" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">답변</v-card-title>
                <v-divider />
                <v-card-text>
                    <naver-smarteditor v-model="form.reply" id="reply" rows="10"></naver-smarteditor>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn outlined color="grey" @click="form.reply = null">초기화</v-btn>
                    <v-spacer />
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-card-actions>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import WorkImgsOverlay from "@/components/console/auction/work-imgs-overlay.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import api from "@/api";
import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        ImagePopup,
        WorkImgsOverlay,
        NaverSmarteditor,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                reply: undefined,

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
            },
        };
    },
    computed: {
        headline() {
            return "폼메일 상세";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    var { form } = await api.console.forms.get({ _id: this.$route.params._form });
                    this.form = form;

                    if (form.thumb) this.form.thumb = await api.getResource(form.thumb);
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { form } = this.form._id ? await api.console.forms.put(this.form) : await api.console.forms.post(this.form);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (this.form.thumb) await api.console.forms.postThumb(form, this.form.thumb);

                alert(this.form._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        isCode(codes = []) {
            return codes.includes(this.form.code);
        },
    },
};
</script>
