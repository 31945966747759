<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <list-heading v-model="showSearch" title="시리즈 목록">
            </list-heading>
            <!-- <program-list-search v-model="filter" :showSearch="showSearch" @search="manualSearch" /> -->
            <v-data-table :items="items" :headers="headers" hide-default-footer disable-sort class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <div slot="item.thumb" slot-scope="{ value }" class="py-4">
                    <image-popup :src="value?.url" size="48" />
                </div>
                <template v-slot:[`item.actions`]="{ item }">
                    <program-view :value="item" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" text icon color="primary" large><v-icon>mdi-pencil</v-icon></v-btn>
                        </template>
                    </program-view>
                    <v-btn icon text color="red" @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="my-4" @input="search()" />
            <program-view :value="filter" @input="update">
                <template #activator="{ attrs, on }">
                    <v-btn v-bind="attrs" v-on="on" fab fixed right bottom color="primary" large><v-icon>mdi-pencil</v-icon></v-btn>
                </template>
            </program-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ProgramView from "@/components/console/auction/program-view.vue";
// import ProgramListSearch from "@/components/console/auction/program-list-search.vue";

import api from "@/api";


const headers = [
    { text: "헤더 노출 순서", value: "priority", width: 140 },
    { text: "게시판 노출 순서", value: "boardNum", width: 140 },
    { text: "노출여부", value: "show" },
    { text: "시리즈 제목", value: "subject" },
    { text: "시리즈 서브 제목", value: "subTitle" },
    { text: "", value: "actions", width: 140, align: "right" },
];

export default {
    components: {
        ImagePopup,
        ListHeading,
        ProgramView,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            filter: {
                code: this.$route.query.code,
                language: "en"
            },

            showSearch: true,

            page: +this.$route.query.page || 1,
            totalCount: 0,
            limit: 10,

            programs: [],
            headers,
        };
    },
    computed: {
        pageCount() {
            return Math.ceil(this.totalCount / this.limit);
        },
        items() {
            return (this.programs || []).map((item, index) => ({ ...item, index: +this.totalCount - (+this.page - 1) * +this.limit - index }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
            this.init()
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        manualSearch() {
            this.page = 1;
            this.search();
        },
        async search(routable = true) {
            this.code = this.$route.query.code;

            if (routable) this.$router.push({ query: Object.assign({}, this.filter, { page: this.page }) });

            var {
                summary: { totalCount },
                programs,
            } = await api.console.auction.programs.gets({
                headers: { skip: (this.page - 1) * this.limit, limit: this.limit },
                params: this.filter,
            });

            this.programs = programs;
            this.totalCount = totalCount;
        },
        async remove(item) {
            if (!confirm(`삭제하시겠습니까?`)) return;

            await api.console.auction.programs.delete(item);
            this.programs = this.programs.filter((content) => content._id !== item._id);
            alert("광고가 삭제되었습니다.");
        },
        async update(item) {
            const index = this.programs.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.programs.splice(index, 1, item);
            else {
                this.programs = [item, ...this.programs];
                this.totalCount += 1;
            }
            this.init()
        },
    },
};
</script>
