var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.headline))]), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.form.writer || {}).companyName,
      "label": "회사명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.form.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form.writer || {}).phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer || {}, "phone", $$v);
      },
      expression: "(form.writer || {}).phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form.writer || {}).email,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer || {}, "email", $$v);
      },
      expression: "(form.writer || {}).email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "문의유형",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form.writer || {}).type,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer || {}, "type", $$v);
      },
      expression: "(form.writer || {}).type"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-divider'), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.form.reply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reply", $$v);
      },
      expression: "form.reply"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        _vm.form.reply = null;
      }
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }