<template>
    <v-dialog v-model="shows" max-width="900" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-if="isLoaded" height="100%">
            <v-card-title>
                <template v-if="isCreate">생성</template>
                <template v-else>상세</template>
                <v-spacer />
                <v-icon @click="shows = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-row no-gutters>
                    <v-col cols="12" sm="5">
                        <v-card-subtitle>썸네일(382*382)</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-preview-input v-model="inputs.thumb" :aspect-ratio="1 / 1" />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <v-divider v-show="!isMobile" vertical />
                        <v-divider v-show="isMobile" />
                    </v-col>
                    <v-col>
                        <v-card-subtitle>기본 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-text-field label="광고솔루션 시리즈" v-model="inputs.subject" class="my-3" />
                            <v-text-field label="광고솔루션 서브 제목" v-model="inputs.subTitle" class="my-3" />
                            <v-text-field label="광고솔루션 설명" v-model="inputs.content" class="my-3" />
                            <v-select label="헤더 노출여부" v-model="inputs.show" :items="['true', 'false']" class="my-3" />
                            <v-text-field label="헤더 노출순서" v-model="inputs.priority" class="my-3" />
                            <v-text-field label="게시판 노출순서" v-model="inputs.boardNum" class="my-3" />
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn large color="primary" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import RowDateTime from "../dumb/row-date-time.vue";
import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";

import api from "@/api";
import detect from "@/plugins/detect";

const makeProgram = () => ({
    thumb: null,

    subject: null,
    subTitle: null,
    content: null,

    groups: null,

    show: false,

    code: null,
    priority: null,
    boardNum: null
});

export default {
    components: {
        RowDateTime,
        VPreviewInput,
    },
    props: {
        value: { type: Object },
    },
    data: () => ({
        shows: false,
        isLoaded: false,
        inputs: {},

        categories: [],

        isMobile: detect == "mobile",
    }),
    computed: {
        _program() {
            return this.value?._id;
        },
        isCreate() {
            return !this._program;
        },
    },
    methods: {
        async init() {
            this.inputs.language = this.$props.value.language
            
            this.inputs.code = this.$route.query.code;

            this.isLoaded = false;
            if (this.isCreate) {
                this.inputs = { ...makeProgram() };
            } else {
                const { program } = await api.console.auction.programs.get({ _id: this._program });
                if (program.thumb) program.thumb = await api.getResource(program.thumb.url);
                this.inputs = program;
            }
            this.isLoaded = true;
        },

        async save() {
            try {
                this.inputs.code = this.$route.query.code;
                this.inputs.language = this.$props.value.language

                const { _thumb, thumb, ...inputs } = this.inputs;

                // Document POST / PUT
                let program;
                if (this.isCreate) [program] = [(await api.console.auction.programs.post(inputs))?.program];
                else [program] = [(await api.console.auction.programs.put(inputs))?.program];

                // Files POST / PUT
                if (_thumb) await api.console.auction.files.delete({ _id: _thumb });
                if (thumb) {
                    const { file } = await api.console.auction.files.post({ _program: program._id, index: "thumb" }, thumb);

                    program._thumb = file._id;
                    [program] = [(await api.console.auction.programs.put(program))?.program];
                    program.thumb = file;
                }

                alert("저장되었습니다");
                this.$emit("input", program);
                this.shows = false;
                this.init()
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
};
</script>
