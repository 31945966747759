<template>
    <v-row no-gutters class="my-3">
        <v-col class="mr-3">
            <v-menu v-model="showsDate" :close-on-content-click="false">
                <template #activator="{ attrs, on }">
                    <v-text-field :label="`${label}(날짜)`" :value="date" v-bind="{ ...attrs, ...attrs_input }" v-on="on" readonly />
                </template>
                <v-date-picker v-model="date" @change="showsDate = false" />
            </v-menu>
        </v-col>
        <v-col>
            <v-menu v-model="showsTime" :close-on-content-click="false">
                <template #activator="{ attrs, on }">
                    <v-text-field :label="`${label}(시간)`" :value="time" v-bind="{ ...attrs, ...attrs_input }" v-on="on" readonly />
                </template>
                <v-time-picker v-model="time" use-seconds @click:seconds="showsTime = false" />
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        value: String,
        label: String,
        attrs_input: { type: Object, default: () => ({}) },
    },
    data: () => ({
        showsDate: false,
        showsTime: false,
        date: null,
        time: null,
    }),
    computed: {
        // dateTime(){
        //     this.time.
        //     return dayjs(this.date).set("hour":)
        // }
    },
    mounted() {
        this.init();
    },
    watch: {
        date() {
            this.emit();
        },
        time() {
            this.emit();
        },
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            const today = this.value || Date.now();
            this.date = dayjs(today).format("YYYY-MM-DD");
            this.time = dayjs(today).format("HH:mm:ss");
        },
        emit() {
            this.$emit("input", `${this.date} ${this.time}`);
        },
    },
};
</script>

<style></style>
